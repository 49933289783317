/*---------------------
  Contact Section
-----------------------*/
.contact-section a {
	color: black;
}

.contact-section a:hover {
	color: #dfa974;
}

.contact-section {
	padding-top: 80px;
	padding-bottom: 80px;
}
.contact-text h2 {
	font-size: 44px;
	color: #19191a;
	margin-bottom: 24px;
}
.contact-text p {
	color: #707079;
}
.contact-text table tbody tr td {
	font-size: 16px;
	color: #19191a;
	line-height: 36px;
	font-weight: 500;
}
.contact-text table tbody tr td.c-o {
	color: #707079;
	width: 75px;
}
.contact-form input,select {
	width: 100%;
	height: 50px;
	border: 1px solid #e1e1e1;
	font-size: 16px;
	color: #aaaab3;
	padding-left: 25px;
	margin-bottom: 28px;
}
.contact-form input::-webkit-input-placeholder {
	color: #aaaab3;
}
.contact-form input::-moz-placeholder {
	color: #aaaab3;
}
.contact-form input:-ms-input-placeholder {
	color: #aaaab3;
}
.contact-form input::-ms-input-placeholder {
	color: #aaaab3;
}
.contact-form input::placeholder {
	color: #aaaab3;
}
.contact-form textarea {
	width: 100%;
	height: 150px;
	border: 1px solid #e1e1e1;
	font-size: 16px;
	color: #aaaab3;
	padding-left: 25px;
	padding-top: 12px;
	margin-bottom: 33px;
	resize: none;
}
.contact-form textarea::-webkit-input-placeholder {
	color: #aaaab3;
}
.contact-form textarea::-moz-placeholder {
	color: #aaaab3;
}
.contact-form textarea:-ms-input-placeholder {
	color: #aaaab3;
}
.contact-form textarea::-ms-input-placeholder {
	color: #aaaab3;
}
.contact-form textarea::placeholder {
	color: #aaaab3;
}
.contact-form button {
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	color: #ffffff;
	letter-spacing: 2px;
	background: #dfa974;
	border: none;
	padding: 14px 34px 13px;
	display: inline-block;
}
.map {
	height: 470px;
	-webkit-box-shadow: 0px 14px 35px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 14px 35px rgba(0, 0, 0, 0.15);
	margin-top: 75px;
}
.map iframe {
	width: 100%;
}
.header-section a {
    color: black;
}

.header-section a:hover {
    text-decoration: none !important; 
    color: #DFA974;

}

/*---------------------
  Header
-----------------------*/

.header-section.header-normal .menu-item {
	-webkit-box-shadow: 0px 12px 15px rgba(36, 11, 12, 0.05);
	box-shadow: 0px 12px 15px rgba(36, 11, 12, 0.05);
}
.top-nav {
	border-bottom: 1px solid #e5e5e5;
}
.top-nav .tn-left li {
	list-style: none;
	display: inline-block;
	font-size: 16px;
	color: #19191a;
	font-weight: 500;
	padding: 14px 0 12px;
	margin-right: 64px;
	position: relative;
}
.top-nav .tn-left li:after {
	position: absolute;
	right: -32px;
	top: 0;
	width: 1px;
	height: 100%;
	background: #e5e5e5;
	content: "";
}
.top-nav .tn-left li:last-child {
	margin-right: 0;
}
.top-nav .tn-left li:last-child:after {
	display: none;
}
.top-nav .tn-left li i {
	color: #dfa974;
	margin-right: 4px;
}
.top-nav .tn-right {
	text-align: right;
}
.top-nav .tn-right .top-social {
	display: inline-block;
	margin-right: 35px;
	padding: 13px 0;
}
.top-nav .tn-right .top-social a {
	display: inline-block;
	font-size: 16px;
	color: #19191a;
	margin-left: 15px;
}
.top-nav .tn-right .bk-btn {
	display: inline-block;
	font-size: 13px;
	font-weight: 700;
	padding: 16px 28px 15px;
	background: #dfa974;
	color: #ffffff;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.menu-item {
	position: relative;
	z-index: 9;
	background: #222736;
}
.menu-item .logo {
	
}
.menu-item .logo a {
	display: inline-block;
}
.menu-item .nav-menu {
	text-align: right;
	margin: 15px auto;
}
.menu-item .nav-menu .mainmenu {
	display: inline-block;
}
.menu-item .nav-menu .mainmenu li {
	list-style: none;
	display: inline-block;
	position: relative;
	z-index: 1;
}
.menu-item .nav-menu .mainmenu li.active a:after {
	opacity: 1;
}
.menu-item .nav-menu .mainmenu li:hover>a:after {
	opacity: 1;
}
.menu-item .nav-menu .mainmenu li:hover .dropdown {
	top: 77px;
	opacity: 1;
	visibility: visible;
}
.menu-item .nav-menu .mainmenu li a {
	font-size: 16px;
	color: whitesmoke;
	margin-right: 42px;
	font-weight: 500;
	display: inline-block;
	padding: 27px 0;
	position: relative;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.menu-item .nav-menu .mainmenu li a:after {
	position: absolute;
	left: 0;
	top: 52px;
	width: 100%;
	height: 2px;
	background: #dfa974;
	content: "";
	opacity: 0;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.menu-item .nav-menu .mainmenu li .dropdown {
	position: absolute;
	left: 0;
	top: 97px;
	width: 180px;
	background: #ffffff;
	z-index: 9;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-box-shadow: 0px 9px 15px rgba(25, 25, 26, 0.05);
	box-shadow: 0px 9px 15px rgba(25, 25, 26, 0.05);
}
.menu-item .nav-menu .mainmenu li .dropdown li {
	list-style: none;
	display: block;
}
.menu-item .nav-menu .mainmenu li .dropdown li a {
	font-size: 16px;
	color: #19191a;
	display: block;
	text-align: left;
	padding: 8px 15px;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.menu-item .nav-menu .mainmenu li .dropdown li a:hover {
	color: #dfa974;
}
.menu-item .nav-menu .mainmenu li .dropdown li a:after {
	display: none;
}
.menu-item .nav-menu .nav-right {
	display: inline-block;
	margin-left: 43px;
	padding: 27px 0px;
}
.menu-item .nav-menu .nav-right i {
	font-size: 16px;
	color: #19191a;
	cursor: pointer;
}
.canvas-open {
	display: none;
}
.offcanvas-menu-wrapper {
	display: none;
}

.about-pic {
	top: 27%;
	display: flex;
	vertical-align: middle;
	align-items: center;
	position: relative;
}


@media only screen and (min-width:0px) and (max-width: 991px) { 
	.hero-text {
		display: none;
	}

	.hero-section {
		height: 0px;
	}

}

@media only screen and (min-width:0px) and (max-width: 450px) { 
	.hero-section {
		height: 450px;
	}

}

@media only screen and (max-width: 991px) {

	.top-nav {
		display: none;
	}
	.menu-item .nav-menu {
		display: none;
	}
	.offcanvas-menu-overlay {
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.7);
		z-index: 98;
		height: 100%;
		width: 100%;
		visibility: hidden;
		-webkit-transition: 0.3s;
		-o-transition: 0.3s;
		transition: 0.3s;
	}
	.offcanvas-menu-overlay.active {
		visibility: visible;
	}
	.canvas-open {
		position: absolute;
		right: 40px;
		top: 25px;
		font-size: 22px;
		width: 30px;
		height: 30px;
		color: whitesmoke;
		border: 1px solid whitesmoke;
		border-radius: 2px;
		line-height: 33px;
		text-align: center;
		z-index: 100;
		display: block;
		cursor: pointer;
	}
	.offcanvas-menu-wrapper {
		position: fixed;
		left: -300px;
		top: 0;
		width: 300px;
		z-index: 999;
		background: #ffffff;
		text-align: center;
		overflow-y: auto;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all 0.5s;
		-o-transition: all 0.5s;
		transition: all 0.5s;
		padding: 80px 30px 30px 30px;
		display: block;
	}
	.offcanvas-menu-wrapper .canvas-close {
		height: 30px;
		width: 30px;
		border: 1px solid #19191a;
		border-radius: 50%;
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		position: absolute;
		right: 30px;
		top: 30px;
		padding-right: 1px;
	}
	.offcanvas-menu-wrapper .search-icon {
		color: #19191a;
		text-align: center;
		cursor: pointer;
		margin-bottom: 10px;
	}
	.offcanvas-menu-wrapper .header-configure-area {
		margin-bottom: 30px;
	}
	
	.offcanvas-menu-wrapper .header-configure-area .bk-btn {
		display: inline-block;
		font-size: 13px;
		font-weight: 700;
		padding: 16px 28px 15px;
		background: #dfa974;
		color: #ffffff;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
	.offcanvas-menu-wrapper .mainmenu {
		display: none;
	}
	.offcanvas-menu-wrapper .slicknav_menu {
		background: transparent;
		padding: 0;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_btn {
		float: none;
		margin: 0 0 10px 0;
		display: none;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_nav {
		display: block !important;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_nav ul {
		margin: 0;
		text-align: left;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_nav ul li .dropdown {
		padding-left: 20px;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_nav ul li a {
		margin: 0;
		padding: 10px;
		font-weight: 600;
		font-size: 14px;
		color: #19191a;
		border-bottom: 1px solid #e5e5e5;
		-webkit-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_nav ul li a:hover {
		border-radius: 0;
		background: transparent;
		color: #dfa974;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_nav .slicknav_row a {
		border-bottom: 0;
		padding-left: 0;
		-webkit-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_nav .slicknav_row span {
		color: #19191a;
		-webkit-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_nav .slicknav_row:hover a {
		color: #dfa974;
	}
	.offcanvas-menu-wrapper .slicknav_menu .slicknav_nav .slicknav_row:hover span {
		color: #dfa974;
	}
	.offcanvas-menu-wrapper .top-social {
		display: inline-block;
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.offcanvas-menu-wrapper .top-social a {
		display: inline-block;
		font-size: 16px;
		color: #19191a;
		margin-right: 20px;
	}
	.offcanvas-menu-wrapper .top-social a:last-child {
		margin-right: 0;
	}
	.offcanvas-menu-wrapper .top-widget li {
		list-style: none;
		font-size: 16px;
		color: #19191a;
		font-weight: 500;
		line-height: 28px;
	}
	.offcanvas-menu-wrapper .top-widget li i {
		color: #dfa974;
		margin-right: 4px;
	}
	.offcanvas-menu-wrapper.show-offcanvas-menu-wrapper {
		left: 0;
		opacity: 1;
		visibility: visible;
	}
	.about-text {
		margin-bottom: 30px;
	}
	.footer-section .copyright-option ul {
		text-align: center;
		margin-bottom: 10px;
	}
	.footer-section .copyright-option .co-text {
		text-align: center;
	}
	.about-pic img {
		margin-bottom: 20px;
	}
	.room-booking {
		padding: 0;
		margin-top: 40px;
	}
	.contact-form {
		padding-top: 30px;
	}
	.hero-text {
		margin-bottom: 40px;
	}
}
/* Tablet Device: 768px */